.hero {
    position: relative;
  }
  .hero h3 {
    font-weight: 100;
    letter-spacing: 2px;
  }
  .hero h1 {
    font-size: 60px;
    font-weight: bold;
  }
  
  .hero h1 span {
    color: #e99e2f;
  }
  .hero h2 {
    font-size: 50px;
    font-weight: bold;
    color: #ebac26;
  }
  .hero h2 span {
    color: black;
  }
  .hero p {
    color: #878e99;
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
  }
  .hero_btn {
    margin-top: 20%;
  }
  .hero_btn h4 {
    font-weight: 500;
    letter-spacing: 2px;
    color: #43474b;
    font-size: 15px;
    margin: 30px 0 30px 0;
  }
  .hero_btn button {
    margin-right: 30px;
  }
  .hero_btn i {
    font-size: 20px;
  }
  
  .hero_btn button img {
    width: 20px;
    height: 20px;
  }
  .hero .left {
    margin-right: 10%;
  }
  .hero .right {
    width: 40%;
    position: relative;
  }
  
  .hero .right_img img {
    
    top: 20rem;
    right: 10%;
    width: 600px;
    height:400px
  }
 
  
  @media (max-width: 768px) {
    .hero .right,
    .hero .left {
      width: 100%;
      margin-left: 5px;
    }
    .hero .right_img img {
  
      width: 360px;
      height:280px;
     
    }
    .hero .f_flex {
      flex-direction: column;
      flex-direction: column-reverse;
      z-index:99;
    }
    .hero h2{
      font-size:33px;
    }
    
  }
  